



















import { Vue, Component, Prop, Watch, mixins } from 'nuxt-property-decorator';
import { BlokContentList } from '../../types';
import BreakpointMixin from '../../mixins/BreakpointMixin';

@Component
export default class ContentList extends mixins(BreakpointMixin) {
  @Prop() blok!: BlokContentList;
  // -> GSAP animation
  $refs!: { container: HTMLElement, items: HTMLElement[] };
  animate (): void {
    this.$gsap.to(this.$refs.items, {
      scrollTrigger: {
        trigger: () => this.$refs.container,
        start: this.breakpointUp('md') ? 'center bottom-=50' : 'top bottom'
      },
      delay: 0.2,
      y: 0,
      opacity: 1,
      duration: 1.2,
      ease: 'power1.out',
      stagger: {
        each: 0.3
      }
    });
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.items, {
      y: 100,
      opacity: 0
    });
    this.animate();
  }
}
